import { ChangeEvent } from 'react';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import {
  InputSearchContainer, SearchContainer,
} from './styles';

interface SearchInterface {
  searchTerm: string;
  onChangeSearchTerm: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function Search({
  searchTerm,
  onChangeSearchTerm,
}: SearchInterface) {
  return (
    <OpacityAnimation delay={0.1}>
      <SearchContainer>
        <InputSearchContainer>
          <input
            value={searchTerm}
            type="text"
            placeholder="Pesquisar assinante (por nome, celular, e-mail, ou identificador na empresa)"
            onChange={onChangeSearchTerm}
          />
        </InputSearchContainer>
      </SearchContainer>
    </OpacityAnimation>
  );
}
