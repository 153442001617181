/* eslint-disable max-len */
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import differenceBetweenDates from '../../../../utils/differenceBetweenDates';
import formatDate from '../../../../utils/formatDate';
import splitArray from '../../../../utils/splitArray';
import { MappedSubscriber } from '../../types';

interface UseSearchesInterface {
  fullSubscribers: MappedSubscriber[];
  setSubscribersSplited: Dispatch<SetStateAction<Array<MappedSubscriber[]>>>;
  setFilteredSubscribers: Dispatch<SetStateAction<MappedSubscriber[]>>;
}

export default function useSearches({
  fullSubscribers,
  setSubscribersSplited,
  setFilteredSubscribers,
}: UseSearchesInterface) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCupom, setSelectedCupom] = useState({ value: '', label: 'Filtrar por cupom' });
  const [selectedCompany, setSelectedCompany] = useState({ value: '', label: 'Filtrar por empresa' });
  const [selectedStatus, setSelectedStatus] = useState({ value: '', label: 'Filtrar por status' });
  const [selectedEngagement, setSelectedEngagement] = useState({ value: '', label: 'Filtrar por engajamento' });
  const [selectedKind, setSelectedKind] = useState('');
  const [selectedAgeRange, setSelectedAgeRange] = useState([0, 13]);
  const [choosedAgeRange, setChoosedAgeRange] = useState([0, 13]);

  const [isSpecificRegistrationDate, setIsSpecificRegistrationDate] = useState(false);
  const [registrationDateBeingShown, setRegistrationDateBeingShown] = useState('');
  const [isSpecificActivationDate, setIsSpecificActivationDate] = useState(false);
  const [activationDateBeingShown, setActivationDateBeingShown] = useState('');
  const [modalSpecificRegistrationDateShow, setModalSpecificRegistrationDateShow] = useState(false);
  const [modalSpecificActivationDateShow, setModalSpecificActivationDateShow] = useState(false);

  const filterSubscribers = useCallback(() => {
    const subscribersFiltered = fullSubscribers.filter((sbs) => {
      const companyFilter = sbs.company === selectedCompany.value || selectedCompany.value === '';
      const cupomFilter = sbs.cupom === selectedCupom.value || selectedCupom.value === '';
      const statusFilter = sbs.status === selectedStatus.value || selectedStatus.value === '';
      const engagementFilter = sbs.engagementStatus === selectedEngagement.value || selectedEngagement.value === '';
      const searchTermFilter = (
        sbs.name.toLowerCase().includes(searchTerm.toLowerCase())
        || sbs.email.toLowerCase().includes(searchTerm.toLowerCase())
        || sbs.cellphone.includes(searchTerm)
        || sbs?.collaboratorId?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      const subscriberKindFilter = sbs.kind === selectedKind || selectedKind === '';
      const ageFilter = (sbs.kids.some((kid) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const kidAge = differenceBetweenDates(formatDate(kid.birthDate)!).years

        return Number((choosedAgeRange[1])) === kidAge || Number(choosedAgeRange[1]) === 13
      }) || (sbs.kids.length === 0 && choosedAgeRange[1] === 13));

      const userRegistrationDate = sbs.registrationDate.split(' ')[0];
      const [regDay, regMonth, regYear] = userRegistrationDate.split('/');
      const formattedRegistrationDate = `${Number(regDay)}/${Number(regMonth)}/${regYear}`;
      const registrationDateFilter = isSpecificRegistrationDate ? formattedRegistrationDate === registrationDateBeingShown : true;

      const userActivationDate = sbs.activateDate;
      const [actDay, actMonth, actYear] = userActivationDate.split('/');
      const formattedActivationDate = `${Number(actDay)}/${Number(actMonth)}/${actYear}`;
      const activationDateFilter = isSpecificActivationDate ? formattedActivationDate === activationDateBeingShown : true;

      return companyFilter && cupomFilter && statusFilter && searchTermFilter && subscriberKindFilter && engagementFilter && ageFilter && registrationDateFilter && activationDateFilter;
    });

    const subscribersSplitedbyFilters = splitArray(subscribersFiltered);
    setSubscribersSplited(subscribersSplitedbyFilters);
    setFilteredSubscribers(subscribersSplitedbyFilters[0]);
  }, [activationDateBeingShown, choosedAgeRange, fullSubscribers, isSpecificActivationDate, isSpecificRegistrationDate, registrationDateBeingShown, searchTerm, selectedCompany.value, selectedCupom.value, selectedEngagement.value, selectedKind, selectedStatus.value, setFilteredSubscribers, setSubscribersSplited]);

  useEffect(() => {
    filterSubscribers();
  }, [filterSubscribers]);

  const handleCompanyChange = useCallback((event: { value: string, label: string }) => {
    setSelectedCompany(event);
  }, []);

  const handleCupomChange = useCallback((event: { value: string, label: string }) => {
    setSelectedCupom(event);
  }, []);

  const handleStatusChange = useCallback((event: { value: string, label: string }) => {
    setSelectedStatus(event);
  }, []);

  const handleEngagementChange = useCallback((event: { value: string, label: string }) => {
    setSelectedEngagement(event);
  }, []);

  const handleKindChange = useCallback((event: string) => {
    setSelectedKind(event);
  }, []);

  const handleChangeSearchTerm = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }, []);

  const handleAgeSelection = useCallback((age: number[]) => {
    setChoosedAgeRange(age);
  }, []);

  function handleIsSpecificRegistrationDate(isSpecific: boolean) {
    setIsSpecificRegistrationDate(isSpecific);

    if (!isSpecific) {
      setRegistrationDateBeingShown('');
    }
  }

  function handleModalSpecificRegistrationDateShow() {
    setModalSpecificRegistrationDateShow((prevState) => !prevState);
  }

  function handleIsSpecificActivationDate(isSpecific: boolean) {
    setIsSpecificActivationDate(isSpecific);

    if (!isSpecific) {
      setActivationDateBeingShown('');
    }
  }

  function handleModalSpecificActivationDateShow() {
    setModalSpecificActivationDateShow((prevState) => !prevState);
  }

  const handleSpecificRegistrationDateChange = useCallback((date: string) => {
    setIsSpecificRegistrationDate(true);
    const [year, month, day] = date.split('-');
    const formattedDate = `${Number(day)}/${Number(month)}/${year}`;
    setRegistrationDateBeingShown(formattedDate);
  }, []);

  const handleSpecificActivationDateChange = useCallback((date: string) => {
    setIsSpecificActivationDate(true);
    const [year, month, day] = date.split('-');
    const formattedDate = `${Number(day)}/${Number(month)}/${year}`;
    setActivationDateBeingShown(formattedDate);
  }, []);

  return {
    searchTerm,
    selectedCupom,
    selectedCompany,
    selectedStatus,
    selectedKind,
    handleCompanyChange,
    handleCupomChange,
    handleStatusChange,
    handleKindChange,
    handleChangeSearchTerm,
    selectedEngagement,
    handleEngagementChange,
    handleAgeSelection,
    selectedAgeRange,
    setSelectedAgeRange,
    handleIsSpecificRegistrationDate,
    isSpecificRegistrationDate,
    handleModalSpecificRegistrationDateShow,
    registrationDateBeingShown,
    handleIsSpecificActivationDate,
    isSpecificActivationDate,
    handleModalSpecificActivationDateShow,
    activationDateBeingShown,
    modalSpecificRegistrationDateShow,
    modalSpecificActivationDateShow,
    handleSpecificRegistrationDateChange,
    handleSpecificActivationDateChange,
  };
}
