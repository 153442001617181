import { Col, Container, Row } from 'react-bootstrap';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import renderArray from '../../../../utils/renderArrayWithComa';
import { MappedSubscriber } from '../../types';
import {
  Card,
} from './styles';

interface ListInterface {
  filteredList: MappedSubscriber[];
  handleOpenDetailModal: (subscriberId: string) => void;
}

export default function List({
  filteredList,
  handleOpenDetailModal,
}: ListInterface) {
  const smallTagColorByStatusLiterals: { [key: string]: string } = {
    Ativo: 'green',
    Cancelado: 'orange',
    Teste: '',
    Suspenso: 'gray',
  };

  const engagementStatusMap: { [key: string]: string } = {
    great: '🟢',
    warn: '🟡',
    bad: '🔴',
    horrible: '🔴',
  };

  return (
    <Container>
      <Row xs={1} md={2} lg={2}>
        {filteredList?.map((subscriber) => (
          <Col key={subscriber.id}>
            <OpacityAnimation delay={0.1}>
              <Card onClick={() => handleOpenDetailModal(subscriber.id)}>
                <div className="info">
                  <div className="card-title">
                    <strong>{subscriber.name}</strong>
                    <small className={smallTagColorByStatusLiterals[subscriber.status]}>
                      {subscriber.status}
                    </small>
                  </div>
                  <span>
                    E-mail:
                    {' '}
                    {subscriber.email}
                  </span>
                  <span>
                    Celular:
                    {' '}
                    {subscriber.cellphone}
                  </span>
                  <span>
                    Empresa:
                    {' '}
                    {subscriber.company}
                  </span>
                  <span>
                    Empresa Associada:
                    {' '}
                    {subscriber.associated_company}
                  </span>
                  <span>
                    Cupom:
                    {' '}
                    {subscriber.cupom ?? 'Não utilizado'}
                  </span>
                  <span>
                    Identificador na empresa:
                    {' '}
                    {subscriber.collaboratorId ?? 'Não informado'}
                  </span>
                  <span>
                    Ativado em
                    {' '}
                    {subscriber.activateDate}
                  </span>
                  <span>
                    Engajamento:
                    {' '}
                    {subscriber.engagementStatus ? engagementStatusMap[subscriber.engagementStatus] : 'Não identificado'}
                  </span>
                  <span>
                    Empresas anteriores:
                    {' '}
                    {(subscriber.pastCompanies && subscriber.pastCompanies.length > 0) ? renderArray(subscriber.pastCompanies) : 'Nenhuma'}
                  </span>
                </div>
              </Card>
            </OpacityAnimation>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
